exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-brands-tsx": () => import("./../../../src/pages/brands.tsx" /* webpackChunkName: "component---src-pages-brands-tsx" */),
  "component---src-pages-career-tsx": () => import("./../../../src/pages/career.tsx" /* webpackChunkName: "component---src-pages-career-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-responsibility-tsx": () => import("./../../../src/pages/responsibility.tsx" /* webpackChunkName: "component---src-pages-responsibility-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-news-list-tsx": () => import("./../../../src/templates/news-list.tsx" /* webpackChunkName: "component---src-templates-news-list-tsx" */)
}

