module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["zh-Hans","zh-Hant","en"],"defaultLanguage":"zh-Hans","i18nextOptions":{"ns":["index","404","about","article","brands","career","contact","footer","menu","news","responsibility","join-us"],"defaultNS":"","fallbackNS":"","nsSeparator":false,"interpolation":{"escapeValue":false},"keySeparator":false},"pages":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
